import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_KICC_BACKEND_BASEURL;

// config
const request = axios.create({
  baseURL,
});

const headers = (header) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      ...header && header,
    }
  };
};

// const querystring = (query) => {
//   return {
//     params: query,
//   };
// };

export const registTransaction = (data) => {
  return request.post('/api/trades/webpay',
    data,
    headers(),
  );
};

export const requestApproval = (data) => {
  return request.post('/api/trades/approval',
    data,
    headers(),
  );
};
