import React, { Fragment, useState, useEffect } from 'react';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import PaymentResponse from '../components/views/PaymentResponse';
import ModalError from '../components/ModalError';
import * as GoogleAnalytics from '../lib/google-analytics';

const CheckInPaymentResponseContainer = ({
  history,
}) => {
  const systemId = process.env.REACT_APP_SYSTEM_ID;
  const bsnsCode = process.env.REACT_APP_BSNS_CODE;
  const propertyNo = process.env.REACT_APP_PROPERTY_NO;
  const urlQueryStringParams = queryString.parse(history.location.search);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    history.replace(`/?rsvnNo=${urlQueryStringParams.shopOrderNo}`);
  };

  const payMethodCodeFormatter = (payMethodCode) => {
    return {
      '026': 'BC',
      '016': 'CC',
      '046': 'AX',
      '049': 'FM',
      '050': 'FV',
      '006': 'HA',
      '027': 'HD',
      '028': 'JC',
      '047': 'LC',
      '018': 'NH',
      '029': 'SH',
      '031': 'SS',
      '008': 'VS',
      '021': 'WR',
      '048': 'FD',
    }[payMethodCode] || 'CD';
  };

  useEffect(() => {
    if (urlQueryStringParams && urlQueryStringParams.resCd === '0000') {
      const sanhaAysRequestParams = `${systemId}_${encodeURI(btoa('poco'))}_${encodeURI(btoa(urlQueryStringParams.pgCno))}_${encodeURI(btoa(urlQueryStringParams.amount))}_${encodeURI(btoa(payMethodCodeFormatter(JSON.stringify(urlQueryStringParams.issuerCode))))}_${encodeURI(btoa('KOR'))}_${encodeURI(`${btoa(urlQueryStringParams.shopOrderNo)}#`)}_${encodeURI(btoa(bsnsCode))}_${encodeURI(btoa(propertyNo))}`;
      GoogleAnalytics.customEvent({
        category: 'debug',
        action: 'debug_ays_request_parameter_for_payment',
        label: sanhaAysRequestParams,
      });
      window.location.replace(`https://wingsays.sanhait.com/ays/IMGATE/${systemId}/Check_In?company=${encodeURI(btoa('poco'))}&payRefNo=${encodeURI(btoa(urlQueryStringParams.pgCno))}&amt=${encodeURI(btoa(urlQueryStringParams.amount))}&payMethodCode=${encodeURI(btoa(payMethodCodeFormatter(JSON.stringify(urlQueryStringParams.issuerCode))))}&langTypeCode=${encodeURI(btoa('KOR'))}&reservationNo=${encodeURI(`${btoa(urlQueryStringParams.shopOrderNo)}#`)}bsnsCode=${encodeURI(btoa(bsnsCode))}&propertyNo=${encodeURI(btoa(propertyNo))}`);
    }
    else {
      setModalErrorMessage('결제 요청에 실패 하였습니다.');
      setModalErrorSubMessage(urlQueryStringParams ? urlQueryStringParams.resMsg : '');
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkin_payment_response',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <PaymentResponse />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default CheckInPaymentResponseContainer;