import React, { Fragment, useEffect } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import TermsOfUseList from '../components/views/TermsOfUseList';
import * as GoogleAnalytics from '../lib/google-analytics';

const TermsOfUseListContainer = ({
  history,
  match,
}) => {
  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_terms_of_use',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <TermsOfUseList
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default TermsOfUseListContainer;