import React, { Fragment, useEffect, useCallback } from 'react';
// import { useDispatch } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import KakaoAuth from '../components/views/KakaoAuth';
import * as GoogleAnalytics from '../lib/google-analytics';
// import { bookingListAction } from '../stores/actions';

const KakaoAuthContainer = ({
  history,
}) => {
  // const dispatch = useDispatch();
  const { Kakao } = window;
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;

  const loginWithKakao = useCallback(() => {
    try {
      Kakao.Auth.login({
        success: function(response) {
          getUserInfoWithKakao();
        },
        fail: function(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfoWithKakao = useCallback(async () => {
    try {
      Kakao.API.request({
        url: '/v2/user/me',
        success: function(response) {
          // const parsedPhoneNumber = `0${response.kakao_account.phone_number.split(' ')[1].replace(/-/gi, '')}`;
          // dispatch(bookingListAction.setUserInfo({
          //   phoneNumber: parsedPhoneNumber,
          //   email: response.kakao_account.email,
          // }));
          history.push('/auth/success');
        },
        fail: function(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authKakao = useCallback(async () => {
    try {
      if (isTestMode) history.push('/auth/success');
      loginWithKakao();
    } catch (error) {
      console.log(error);
    }
  }, [history, isTestMode, loginWithKakao]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <KakaoAuth
            authKakao={authKakao}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default KakaoAuthContainer;