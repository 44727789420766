import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import Payment from '../components/views/Payment';
import ModalError from '../components/ModalError';
import * as api from '../api/kicc';
import * as GoogleAnalytics from '../lib/google-analytics';

const CheckInPaymentContainer = ({
  history,
}) => {
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const { bookingItem } = useSelector(state => state.bookingList);

  const registTransaction = async () => {
    try {
      const { data: registedTransaction } = await api.registTransaction({
        mallId: process.env.REACT_APP_KICC_MALL_ID,
        payMethodTypeCode: '11',
        currency: '00',
        amount: bookingItem.roomRate || 1000,
        clientTypeCode: '00',
        returnUrl: process.env.REACT_APP_KICC_CALLBACK_URL,
        deviceTypeCode: 'mobile',
        shopOrderNo: bookingItem.reservationNo,
        orderInfo: {
          goodsName: '객실 숙박료',
        },
      });
      return registedTransaction;
    } catch (error) {
      throw error;
    }
  };

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkin_payment',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <Payment
            registTransactionAPI={registTransaction}
            amount={bookingItem.roomRate || 1000}
            setModalErrorMessage={setModalErrorMessage}
            setModalErrorSubMessage={setModalErrorSubMessage}
            openModalError={openModalError}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default CheckInPaymentContainer;