import React, { Fragment } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';

const Payment = ({
  registTransactionAPI,
  amount,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
}) => {
  const registTransaction = async () => {
    try {
      GoogleAnalytics.customEvent({
        category: 'button_click',
        action: 'button_click_payment_request',
      });
      const registedTransaction = await registTransactionAPI();
      const approvalForm = document.approvalForm;
      approvalForm.resCd.value = registedTransaction.resCd;
      approvalForm.resMsg.value = registedTransaction.resMsg;
      approvalForm.action = registedTransaction.authPageUrl;
      approvalForm.submit();
    } catch (error) {
      setModalErrorMessage('결제 요청에 실패 하였습니다.');
      setModalErrorSubMessage(error.response ? `${error.response.data.code}, ${error.response.data.message}` : error.message);
      openModalError();
    }
  };

  const totalAmountFormatter = (totalAmount) => {
    return totalAmount ? totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : totalAmount;
  };

    return (
      <Fragment>
        <span className='check-in-payment-title'>
          결제하기
        </span>
        <div className='product-name-container'>
          <span>
            상품명
          </span>
          <span className='product-name-value'>
            객실료
          </span>
        </div>
        <div className='total-amount-container'>
          <span>
            총 금액
          </span>
          <span className='total-amount-value'>
            {`${totalAmountFormatter(amount)}원`}
          </span>
        </div>
        <button className='check-in-payment-button' onClick={registTransaction}>결제하기</button>
        <form id='approvalForm' name='approvalForm' method='post'>
          <input type='hidden' id='resCd' name='resCd' />
          <input type='hidden' id='resMsg' name='resMsg' />
        </form>
      </Fragment>
    );
};

export default Payment;