import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import MobilekeyMenual from './MobilekeyMenual';
import CheckOut from './CheckOut';
import * as GoogleAnalytics from '../../lib/google-analytics';

const BookingList = ({
  bookingItem,
  checkOut,
  history,
}) => {
  const [ isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual ] = useState(false);
  const [ isOpenModalCheckOut, setIsOpenModalCheckOut ] = useState(false);

  const openModalMobilekeyMenual = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_mobilekey_menual',
    });
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_request',
    });
    setIsOpenModalCheckOut(true);
  }, []);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const dayFormatter = (day) => {
    return {
      Sun: '일',
      Mon: '월',
      Tue: '화',
      Wed: '수',
      Thu: '목',
      Fri: '금',
      Sat: '토',
    }[day] || day;
  };

  const setBookingPeriod = (date) => {
    return `${moment(date).format('YYYY.MM.DD')} (${dayFormatter(moment(date).format('ddd'))})`;
  };

  return (
    <Fragment>
      <div className='checked-in-background-container'>
        <div className='opacity-background'>
          <span className='welcome-message'>
            {bookingItem.inhsGestName} 고객님,<br/>
            환영합니다.
          </span>
          <div className='booking-id-container'>
            <span className='booking-id-label'>
              예약번호
            </span>
            <span className='booking-id-value'>
              {bookingItem.reservationNo}
            </span>
          </div>
        </div>
      </div>
      <div className='checked-in-booking-card-container'>
        <span className='hotel-name'>
          {bookingItem.propertyName}
        </span>
        <span className='room-name'>
          {roomNoFormatter(bookingItem.roomNo)}
        </span>
        <div className='horizontal-line mt-15 mb-20'></div>
        <div className='userkey-container'>
          <div className='userkey-item'>
            <span className='userkey-item-label'>
              객실 비밀번호
            </span>
            <span className='userkey-item-value'>
              {bookingItem.entranceNo || '-'}
            </span>
          </div>
          <div className='userkey-item'>
            <span className='userkey-item-label'>
              모바일키
              <img className='tip-icon ml-2 mb-4' src='../../assets/images/question-mark.png' alt='' onClick={openModalMobilekeyMenual} />
            </span>
            <span className='userkey-item-value'>
              {bookingItem.mobileRoomKey || '-'}
            </span>
          </div>
        </div>
        <div className='checked-in-booking-period-container'>
          <img className='period-icon mt-2' src='../../assets/images/period.png' alt='' />
          <div className='period-item-container'>
            <div className='period-item mb-8'>
              <span className='period-item-label'>
                체크인
              </span>
              <span className='period-item-value'>
                {bookingItem.arrvDate ? `${setBookingPeriod(bookingItem.arrvDate)} 15:00` : '-'}
              </span>
            </div>
            <div className='period-item'>
              <span className='period-item-label'>
                체크아웃
              </span>
              <span className='period-item-value'>
                {bookingItem.deptDate ? `${setBookingPeriod(bookingItem.deptDate)} 11:00` : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className='check-out-button-container'>
          <span className='check-out-button' onClick={openModalCheckout}>
            비대면 체크아웃 하기
          </span>
        </div>
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;