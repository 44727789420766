import BookingList from '../containers/BookingListContainer';
import TermsOfUseList from '../containers/TermsOfUseListContainer';
import KakaoAuth from '../containers/KakaoAuthContainer';
import KaKaoAuthSuccess from '../containers/KaKaoAuthSuccessContainer';
import Payment from '../containers/CheckInPaymentContainer';
import PaymentSuccess from '../containers/CheckInPaymentResponseContainer';
import Page404 from '../components/views/Page404';

const mainRoutes = {
  path: '/',
  name: 'Booking List',
  component: BookingList,
};

const termsOfUseListRoutes = {
  path: '/terms_of_use',
  name: 'Terms of Use List',
  component: TermsOfUseList,
};

const kakaoAuthRoutes = {
  path: '/auth',
  name: 'Kakao Auth',
  component: KakaoAuth,
};

const kakaoAuthSuccessRoutes = {
  path: '/auth/success',
  name: 'Kakao Auth Success',
  component: KaKaoAuthSuccess,
};

const paymentRoutes = {
  path: '/checkin/payment',
  name: 'KICC Payment',
  component: Payment,
};

const paymentSuccessRoutes = {
  path: '/checkin/payment/response',
  name: 'KICC Payment Response',
  component: PaymentSuccess,
};

const page404Routes = {
  path: '*',
  name: '404 Page',
  component: Page404,
};

export const routesList = [
  mainRoutes,
  termsOfUseListRoutes,
  kakaoAuthRoutes,
  kakaoAuthSuccessRoutes,
  paymentRoutes,
  paymentSuccessRoutes,
  page404Routes,
];
