import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ModalError from '../components/ModalError';
import * as GoogleAnalytics from '../lib/google-analytics';

const KaKaoAuthSuccessContainer = ({
  history,
}) => {
  const systemId = process.env.REACT_APP_SYSTEM_ID;
  const bsnsCode = process.env.REACT_APP_BSNS_CODE;
  const propertyNo = process.env.REACT_APP_PROPERTY_NO;
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const { bookingItem } = useSelector(state => state.bookingList);

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
    history.replace('/');
  }, [history]);

  useEffect(() => {
    if (bookingItem.calcPay === '1') history.push('/checkin/payment');
    else if (bookingItem.calcPay === '3') {
      const sanhaAysRequestParams = `${systemId}_${encodeURI(btoa('poco'))}_${encodeURI(`${btoa(bookingItem.reservationNo)}#`)}_${encodeURI(btoa(bsnsCode))}_${encodeURI(btoa(propertyNo))}`;
      GoogleAnalytics.customEvent({
        category: 'debug',
        action: 'debug_ays_request_parameter',
        label: sanhaAysRequestParams,
      });
      window.location.replace(`https://wingsays.sanhait.com/ays/IMGATE/${systemId}/Check_In?company=${encodeURI(btoa('poco'))}&reservationNo=${encodeURI(`${btoa(bookingItem.reservationNo)}#`)}bsnsCode=${encodeURI(btoa(bsnsCode))}&propertyNo=${encodeURI(btoa(propertyNo))}`);
    }
    else {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage('후불 예약 입니다. 프론트에서 결제 후 재시도 바랍니다.');
      openModalError();
    }
  }, [bsnsCode, propertyNo, bookingItem.reservationNo, systemId, bookingItem.calcPay, openModalError, history]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth_success',
      label: `${bookingItem.reservationNo}_${bookingItem.status}_${bookingItem.reservationName}`,
    });
  }, [bookingItem.reservationName, bookingItem.reservationNo, bookingItem.status]);

  return (
    <Fragment>
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default KaKaoAuthSuccessContainer;